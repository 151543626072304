<template>
  <div class="home">

    <div class="hello_class">
      <HelloWorld msg="Welcome to Your Vue.js App"/>
    </div>

    <div class="slide_class">
      <div class="title_h2">
        <h2>
        セダン・軽・コンパクト
        </h2>
      </div>
      <div class="carouse_class">
        <CarouselsSedan/>
      </div>

      <div class="title_h2">
        <h2>
        ミニバン
        </h2>
      </div>
      <div class="carouse_class">
        <CarouselsMinivan/>
      </div>

      <div class="title_h3">
        <h2>
        SUV
        </h2>
      </div>
      <div class="carouse_class">
        <CarouselsSuv/>
      </div>

      <div class="title_h2">
        <h2>
        商用車・バン
        </h2>
      </div>
      <div class="carouse_class">
        <CarouselsVan/>
      </div>
    </div>

    <div class="big_parking">

      <div class="access">
        <p class="about_p">ACCESS</p>
        <h1>勝間田タイヤ</h1>
        <p class="address_p">静岡県御殿場市茱萸沢241-1</p>
        <a href="https://goo.gl/maps/oabDqVGbjJQn65yk8">Google Maps</a>
      </div>

    </div>

    <Footer/>
  </div>
</template>

<script>
// @ is an alias to /src
import HelloWorld from '@/components/HelloWorld.vue'
import CarouselsSedan from '@/components/CarouselsSedan.vue'
import CarouselsMinivan from '@/components/CarouselsMinivan.vue'
import CarouselsSuv from '@/components/CarouselsSuv.vue'
import CarouselsVan from '@/components/CarouselsVan.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'Home',
  components: {
    HelloWorld,
    CarouselsSedan,
    CarouselsSuv,
    CarouselsMinivan,
    CarouselsVan,
    Footer
  }
}
</script>

<style scoped>
.exterior_class{
  width: 100%;
}
.title_h2{
  font-size: 1rem;
  padding-bottom: 3rem;
  padding-left: 1.5rem;
  text-align:left;
  letter-spacing: 0.12rem;
}
.title_h3{
  font-size: 1rem;
  padding-left: 1.5rem;
  text-align:left
}
.img_class{
  width: 100%;
  padding-bottom: 1rem;
  padding-top: 4rem;
}
.big_parking{
  padding-bottom: 7rem;
}
.slide_class{
}
.hello_class{
  padding-bottom: 4rem;
}
.carouse_class{
  padding-bottom: 7rem;
}
.headline{
  padding-bottom: 2rem;
  padding-top: 1rem;
  letter-spacing: .06em;
}
.parking_p{
  letter-spacing: .06em;
  font-weight: 600;
}

.about_p{
  margin-top: -4rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.6rem;
  padding-top: 4rem;
}
.address_p{
  font-weight: 600;
  font-size: 0.8rem;
  letter-spacing: 0.07rem;
}
.access p{
  text-align:left;
}
.access_p{
  padding-bottom: 2rem;
  font-size: 0.95rem;
  font-weight: 500;
  line-height: 1.7rem;
}

.access a {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  width: 160px;
  height: 50px;
  color: #333;
  font-size: 13px;
  font-weight: 700;
  text-decoration: none;
  background-color: #9099a2;
  border-radius: 40px;
  margin-top: 30px;
}
.access a::after {
  content: '';
  width: 10px;
  height: 10px;
  border-top: 2px solid #d5d5d5;
  border-right: 2px solid #d5d5d5;
  transform: rotate(45deg);
}
.access{
  padding-top: 6rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 3rem;
}
.access h1{
  margin-top: 0.8rem;
  padding-bottom: 0.5rem;
  text-align:left;
  font-size: 1.9rem;
  letter-spacing: 0.12rem;
}

.heading {
   font-size: 26px;
}

.heading span { /*英字テキストの指定*/
   display: flex;
   align-items: center; /*英字テキストと線の上下中央配置*/
   margin-bottom: 10px;
   color: #e5c046;
   font-size: 18px;
   font-style: italic;
   font-family: 'Montserrat', sans-serif;
   text-transform: uppercase; /*英字の大文字表記*/
}

.heading span::before { /*黄色の線を擬似要素で表現*/
   content: '';
   display: inline-block;
   margin-right: 20px;
   width: 40px;
   height: 1px;
   background-color: #e5c046;
}
</style>
