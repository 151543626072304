import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/access',
    name: 'Access',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "access" */ '../views/AboutAccess.vue')
  },
  {
    path: '/faq',
    name: 'Faq',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "faq" */ '../views/AboutFaq.vue')
  },
  {
    path: '/katsumata-taiya',
    name: 'Katsumata-taiya',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "katsumata-taiya" */ '../views/AboutKatsumatataiya.vue')
  },
  {
    path: '/howtobuy',
    name: 'Howtobuy',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "howtobuy" */ '../views/AboutHowtobuy.vue')
  },
  {
    path: '/nh200',
    name: 'Nh200',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "nh200" */ '../views/AboutNh200.vue')
  },
  {
    path: '/grx3',
    name: 'Grx3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "grx3" */ '../views/AboutGrx3.vue')
  },
  {
    path: '/seiberling',
    name: 'Seiberling',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "seiberling" */ '../views/AboutSeiberling.vue')
  },
  {
    path: '/s007a',
    name: 'S007a',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "s007a" */ '../views/AboutS007a.vue')
  },
  {
    path: '/re71rs',
    name: 'Re71rs',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "re71rs" */ '../views/AboutRe71rs.vue')
  },
  {
    path: '/pxrv2',
    name: 'Pxrv2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "pxrv2" */ '../views/AboutPxrv2.vue')
  },
  {
    path: '/luftrv2',
    name: 'Luftrv2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "luftrv2" */ '../views/AboutLuftrv2.vue')
  },
  {
    path: '/grv2',
    name: 'Grv2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "grv2" */ '../views/AboutGrv2.vue')
  },
  {
    path: '/lx100',
    name: 'Lx100',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "lx100" */ '../views/AboutLx100.vue')
  },
  {
    path: '/hl850',
    name: 'hl850',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "hl100" */ '../views/AboutHl850.vue')
  },
  {
    path: '/at002',
    name: 'At002',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "at002" */ '../views/AboutAt002.vue')
  },
  {
    path: '/rd604',
    name: 'Rd604',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rd604" */ '../views/AboutRd604.vue')
  },
  {
    path: '/r710',
    name: 'R710',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "r710" */ '../views/AboutR710.vue')
  },
  {
    path: '/vrx2',
    name: 'Vrx2',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "vrx2" */ '../views/AboutVrx2.vue')
  },
  {
    path: '/vrx3',
    name: 'Vrx3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "vrx3" */ '../views/AboutVrx3.vue')
  },
  {
    path: '/dmv3',
    name: 'Dmv3',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "dmv3" */ '../views/AboutDmv3.vue')
  },
  {
    path: '/newno',
    name: 'Newno',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "newno" */ '../views/AboutNewno.vue')
  },
  {
    path: '/client',
    name: 'Client',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "client" */ '../views/AboutClient.vue')
  },
  {
    path: '/clientad',
    name: 'Clientad',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clientad" */ '../views/AboutClientAD.vue')
  },
  {
    path: '/wholesale',
    name: 'Wholesale',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "clientad" */ '../views/AboutWholesale.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
       return savedPosition
    } else {
       return { x: 0, y: 0 }
		}
	},
  routes
})

export default router
