import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'sanitize.css'
import vuetify from './plugins/vuetify'



Vue.config.productionTip = false

Vue.prototype.$browserBackFlg = false
history.replaceState(null, '', null)
window.addEventListener('popstate', function() {
  Vue.prototype.$browserBackFlg = true

  window.setTimeout(() => {
    Vue.prototype.$browserBackFlg = false
  }, 500)
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
