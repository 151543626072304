<template>
  <v-app>
    <v-navigation-drawer app v-model="drawer" clipped color="#d5d5d5">
      <v-container>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="title">
            <a class="link_class" href="/">
            <p class="link_p">
            （株）勝間田タイヤ
            </p>
            </a>
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

        <v-divider></v-divider>

        <v-list nav dense>
          <v-list-group
          v-for="nav_list in nav_lists"
          :key="nav_list.name"
          :prepend-icon="nav_list.icon"
          no-action
          :append-icon="nav_list.lists ? undefined : ''">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ nav_list.name }}</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item v-for="list in nav_list.lists" :key="list.name" :to="list.link">
              <v-list-item-content>
                <v-list-item-title>{{ list.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-group>
        </v-list>

      </v-container>
    </v-navigation-drawer>


      <v-app-bar color="#d5d5d5" dark app clipped-left>

        <v-app-bar-nav-icon @click="drawer=!drawer"></v-app-bar-nav-icon>

        <a class="img_router" href="/">
        <img class="img_top" src='./assets/logo_white.png'>
        </a>

        <v-spacer></v-spacer>
        <v-toolbar-items class="mr-n5">
          <v-menu offset-y>
            <template v-slot:activator="{on}">
            <v-btn v-on="on" text>Access<v-icon>mdi-menu-down</v-icon></v-btn>
            </template>
            <v-list>
              <v-subheader>Get help</v-subheader>
              <v-list-item v-for="support in supports" :key="support.name" :href="support.link">
              <v-list-item-icon>
              <v-icon>{{ support.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
              <v-list-item-title>{{ support.name }}</v-list-item-title>
              </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
          </v-toolbar-items>
      </v-app-bar>

      <v-main :style="{ background: $vuetify.theme.themes.light.background }">
        <router-view />
      </v-main>
    <v-footer color="#d5d5d5" dark app>
      OPEN 8:00~18:00<v-spacer></v-spacer>(日/祝休み)
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data(){
    return{
        drawer: null,
        nav_lists:[
          {
            name: '夏タイヤ',
            icon: 'mdi-white-balance-sunny',
            lists:[{
                name:'レグノ 乗り心地 ◎',link:'/grx3'
              },
              {
                name:'エコピア おすすめ',link:'/nh200'
              },
              {
                name:'プレイズ ミニバン用',link:'/pxrv2'
              },
              {
                name:'アレンザ 街乗りSUV',link:'/lx100'
              }
            ]
          },
          {
            name: '冬タイヤ',
            icon: 'mdi-snowflake',
            lists:[{
                name:'VRX3 一番効く',link:'/vrx3'
              },
              {
                name:'VRX2 長年の安心感',link:'/vrx2'
              },
              {
                name:'DM-V3 大型SUV用',link:'/dmv3'
              }
            ]
          },
          {
            name: '会社概要',
            icon: 'mdi-domain',
            lists:[{
                name:'会社について',link:'/katsumata-taiya'
              },
              {
                name:'アクセス',link:'/access'
              }
            ]
          },
          {
            name: '法人向け',
            icon: 'mdi-briefcase',
            lists:[{
                name:'法人のお客様へ',link:'/client'
                },
                {
                name:'卸販売について',link:'/wholesale'
                }
            ]
          },
          {
            name: 'ヘルプ',
            icon: 'mdi-help',
            lists:[{
                name:'タイヤの買い方',link:'/howtobuy'
              },
              {
                name:'よくある質問',link:'/faq'
              },
            ]
          }
        ],
        supports:[
          {
            name: '行き方',
            icon: 'mdi-map-marker',
            link:'https://goo.gl/maps/iYWRNefwEfMmGYGaA'
          },
          {
            name: 'Instagram',
            icon: 'mdi-instagram',
            link:'https://www.instagram.com/katsumata_taiya/'
          },
          {
            name: '電話',
            icon: 'mdi-mail',
            link:'tel:0550-89-1219'
          }
        ]
    }
  }
}
</script>

<style scoped>

#app {
  font-family: 'Noto Sans JP', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media screen and (min-width: 520px) {
  .img_top{
    width: 18%;
    height: auto;
  }
}

@media screen and (max-width: 700px) {
  .img_top{
    width: 45%;
    height: auto;
  }
}
.link_class{
  text-decoration: none;
}
.link_p{
  color: #3c3c3c;
}
</style>
