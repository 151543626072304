<template>
  <v-footer
    dark
    padless
  >
    <v-card
      class="flex"
      flat
      tile
      color="#9099a2"
    >
      <v-card-title>
        <strong class="subheading">(株) 勝間田タイヤ<br>ミスタータイヤマン<br>富士山御殿場店</strong>

        <v-spacer></v-spacer>

        <v-btn
          v-for="icon_list in members"
          :key="icon_list.icon"
          class="mx-4"
          dark
          icon
          :href="icon_list.link"
        >
          <v-icon size="24px">

            {{ icon_list.icon }}

          </v-icon>
        </v-btn>

      </v-card-title>

      <v-card
        class="mx-auto"
        max-width="400"
        color="#9099a2"
      >
        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title><strong>所在：静岡県御殿場市茱萸沢２４１−１</strong></v-list-item-title>
            <v-list-item-subtitle>国道138号沿 新御殿場ICから車で3分</v-list-item-subtitle>
            <v-list-item-subtitle>御殿場ICから車で8分</v-list-item-subtitle>
            <v-list-item-subtitle>御殿場プレミアムアウトレットから車で10分</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title><strong>営業時間：8:00-18:00</strong></v-list-item-title>
            <v-list-item-subtitle>作業受付は17:30まで</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title><strong>定休日：日曜 祝日</strong></v-list-item-title>
            <v-list-item-subtitle>12月は日曜 祝日も営業<br>年末年始, 夏季休暇あり</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item>
            <v-list-item-title><strong>電話番号：0550-89-1219</strong></v-list-item-title>
        </v-list-item>

        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title><strong>決済：現金 クレジット QRコード</strong></v-list-item-title>
            <v-list-item-subtitle>各種クレジットカード・PayPay<br>富士山Gコイン</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item three-line>
          <v-list-item-content>
            <v-list-item-title><strong>サービス：乗用車・トラックタイヤ</strong></v-list-item-title>
            <v-list-item-subtitle>セダン・ミニバン・SUV・軽コンパクト・商用車</v-list-item-subtitle>
            <v-list-item-subtitle>トラック・バス・ダンプ・トレーラー</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-card>


      <v-card-text class="py-2 white--text text-center">
        <a class="a_class" href="https://kohki.link" rel="nofollow">
          <p class="link_class">©{{ new Date().getFullYear() }} — <strong>Kohki All Rights Reserved.</strong></p>
        </a>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      icons: [
        'mdi-map-marker',
        'mdi-instagram'
      ],
      link: [
        'https://goo.gl/maps/iYWRNefwEfMmGYGaA',
        'https://goo.gl/maps/iYWRNefwEfMmGYGaA'
      ],
      members: [
      {
        icon: 'mdi-map-marker',
        link: 'https://goo.gl/maps/iYWRNefwEfMmGYGaA'
      }, {
        icon: 'mdi-instagram',
        link: 'https://www.instagram.com/katsumata_taiya/'
      }
    ]
    }),
  }
</script>

<style scoped>
.a_class{
  text-decoration: none;
}
.link_class{
  color: white;
}
</style>
